import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';

import { Container, Menu, MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SortIcon from '@material-ui/icons/Sort';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { SectionBar } from '../../componentsUI/SectionBar';
import TableCollapse from '../../componentsUI/tableCollapse';
import { isEmpty } from '../../utils/ObjectUtils';
import { PageListContent } from '../Common/styled/PageContent';
import { CardWrapperUI } from '../Common/styled/CardWrapperUI';
import Loading from '../Common/Loading';
import { Span } from '../Common/styled/Text';
import { GET_PROTOCOLS } from '../../queries/Protocols/Protocols';
import { ProtocolEditDialog } from './ProtocolEditDialog';
import { ProtocolAddDialog } from './ProtocolAddDialog';
import { HighlightedText } from '../Common/styled/HighlightedText';
import { getProtocolStateLabel } from '../../utils/ProtocolUtils';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { ContainerUI } from '../Common/styled/ContainerUI';

const fieldNames = [
  { label: 'ID', id: 'node.uuid', width: 75 },
  { label: 'protocol', id: 'node.name', width: 325, field: 'NAME', direction: 'ASC' },
  { label: 'protocol.scope', id: 'node.state', width: 200, format: 'PROTOCOLSTATE' },
  { label: 'created.at', id: 'node.createdAt', width: 95, field: 'CREATED_AT', direction: 'ASC', format: 'DATEFORMAT', align: 'center' },
];

export const ProtocolsView = () => {
  const { t } = useTranslation();
  const hospitalUuid = useSelector((state) => state.hospital.uuid);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [selected, setSelected] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);
  const sortData = {
    default: { field: 'NAME', direction: 'ASC' },
  };
  const [orderByField, setOrderByField] = useState(sortData.default.field);
  const [orderByDirection, setOrderByDirection] = useState(sortData.default.direction);

  const { loading, error, data, refetch, fetchMore } = useQuery(
    GET_PROTOCOLS, {
      variables: {
        hospitalUuid,
        first: 30,
        orderBy: { field: orderByField, direction: orderByDirection },
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const fetchMoreProtocols = (fetchMoreCb) => {
    const { endCursor } = data.protocols.pageInfo;

    fetchMore({
      query: GET_PROTOCOLS,
      variables: {
        cursor: endCursor,
        orderBy: { field: orderByField, direction: orderByDirection },
        hospitalUuid,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.protocols.edges;

        return newEdges.length ? {
          protocols: {
            // eslint-disable-next-line no-underscore-dangle
            __typename: previousResult.protocols.__typename,
            totalCount: previousResult.protocols.totalCount,
            pageInfo: fetchMoreResult.protocols.pageInfo,
            edges: [...previousResult.protocols.edges, ...newEdges],
          },
        } : previousResult;
      },
    }).then(({ data: fetchMoreData }) => (
      fetchMoreData.protocols.pageInfo.hasNextPage && fetchMoreCb()
    ));
  };

  const protocol = (data && data.protocols && selected.length > 0) && data.protocols.edges[selected[0]].node;

  const reverseDirection = () => (orderByDirection === 'ASC' ? 'DESC' : 'ASC');

  const handleOrderBy = (value) => {
    if (value.field) {
      if (value.field === orderByField) {
        setOrderByDirection(reverseDirection());
      } else {
        setOrderByDirection(value.direction);
      }

      setOrderByField(value.field);
      refetch();
      setAnchorEl();
      setSelected([]);
    }
  };
  const getTitle = (item) => (
    `${item.node.name}`
  );

  const getSubTitle = (item) => (
    <small>
      <strong>
        <span>ID:&nbsp;</span>
        <HighlightedText>
          {item.node.uuid}
        </HighlightedText>
      </strong>
      <div>
        {t(getProtocolStateLabel(item.node.state))}
      </div>
    </small>
  );

  const handleCloseNew = () => {
    setShowNewModal(false);
    setSelected([]);
    refetch();
  };

  const handleCloseEdit = () => {
    setShowEditModal(false);
    refetch();
  };

  const handleOrder = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const buttons = [
    { name: 'new.protocol', icon: AddIcon, handleClick: () => setShowNewModal(true), disabled: false },
    { name: 'edit.protocol', icon: EditIcon, handleClick: () => setShowEditModal(true), disabled: selected.length !== 1 },
    { name: 'divider', type: 'divider' },
    { name: 'sort.by', icon: SortIcon, handleClick: handleOrder, disabled: false },
  ];

  const OrderIcon = ({ className, direction }) => (
    (direction === 'ASC') ? <ArrowDropDownIcon className={className} /> : <ArrowDropUpIcon className={className} />
  );

  return (
    <ContainerUI>
      <Navbar>
        <ProtocolAddDialog open={showNewModal} onClose={handleCloseNew} />
        <ProtocolEditDialog open={showEditModal} onClose={handleCloseEdit} protocolId={protocol && protocol.uuid} />
        <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
          {fieldNames.filter((filter) => !!filter.field).map((item) => (
            <MenuItem
              key={item.id}
              onClick={() => handleOrderBy(item)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span style={{ width: 150 }}>{t(item.label)}</span>
              {(item.field === orderByField) && <OrderIcon direction={orderByDirection} />}
            </MenuItem>
          ))}
        </Menu>

        <SectionBar title="protocols" items={buttons} />
      </Navbar>
      <Container maxWidth="lg" className="article">
        <PageListContent>
          {loading && isEmpty(data) && <Loading />}
          <CardWrapperUI>
            {
              data && data.protocols && data.protocols.edges
                && (
                  <TableCollapse
                    fieldNames={fieldNames}
                    items={data.protocols.edges}
                    GetTitle={getTitle}
                    GetSubtitle={getSubTitle}
                    // GetCollapse={PatientCollapse}
                    selected={selected}
                    setSelected={setSelected}
                    // handleGoto={handleGoto}
                    isMore={data
                      && data.protocols
                      && data.protocols.pageInfo
                      && data.protocols.pageInfo.hasNextPage}
                    fetchMoreFn={fetchMoreProtocols}
                    order={{ field: orderByField, direction: orderByDirection }}
                    handleOrder={handleOrderBy}
                  />
                )
              // && data.protocols.edges.map((edge) => (
              //   <ProtocolCard key={edge.node.uuid} protocol={edge.node} />
              // ))
            }
          </CardWrapperUI>

          {
            data && data.protocols && data.protocols.edges.length === 0 && (
              <Span>{t('no.protocols')}</Span>
            )
          }
        </PageListContent>
      </Container>
    </ContainerUI>
  );
};
